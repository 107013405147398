<template>
    <app-layout>
        <div class="faq">
            <card>
                <template #icon>
                    <document-icon />
                </template>

                <template #title>{{ $t('faq.title') }}</template>

                <template #description>{{ $t('faq.description') }}</template>

                <div class="faq__questions">
                    <div class="faq__question" v-for="(question, index) in $t('faq.questions')" :key="index">
                        <div class="faq__question-title">
                            <question-icon />

                            <h2>{{ question.question }}</h2>
                        </div>

                        <p v-html="question.answer" />
                    </div>
                </div>
            </card>

            <card>
                <template #title>{{ $t('faq.contacts.title') }}</template>

                <template #description>{{ $t('faq.contacts.description') }}</template>

                <div class="faq__socials">
                    <div class="faq__social">
                        <img src="~@/assets/images/telegram.png" alt="telegram image" />

                        <h2>{{ $t('faq.contacts.telegram.title') }}</h2>

                        <p>@skinpay_support</p>

                        <text-button>
                            {{ $t('faq.contacts.telegram.button') }}
                            <template #icon>
                                <telegram-icon />
                            </template>
                        </text-button>
                    </div>

                    <div class="faq__social">
                        <img src="~@/assets/images/email.png" alt="email image" />

                        <h2>{{ $t('faq.contacts.email.title') }}</h2>

                        <p>support@skinpay.com</p>

                        <text-button>
                            {{ $t('faq.contacts.email.button') }}
                            <template #icon>
                                <email-icon />
                            </template>
                        </text-button>
                    </div>
                </div>
            </card>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from '@/pages/layout/AppLayout';
    import Card from '@/components/Card';
    import DocumentIcon from '@/components/icons/DocumentIcon';
    import EmailIcon from '@/components/icons/EmailIcon';
    import QuestionIcon from '@/components/icons/QuestionIcon';
    import TelegramIcon from '@/components/icons/TelegramIcon';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'Faq',
        components: {
            AppLayout,
            Card,
            DocumentIcon,
            EmailIcon,
            QuestionIcon,
            TelegramIcon,
            TextButton,
        },
    };
</script>

<style lang="scss" scoped>
    .faq > .card {
        &:first-of-type {
            border-radius: 0.8rem 0.8rem 0 0;
        }

        &:last-of-type {
            flex-shrink: 0;
            border-radius: 0 0 0.8rem 0.8rem;
            background-color: #121724;
        }
    }

    .faq__questions {
        display: grid;
        grid-gap: 0.5rem;
    }

    .faq__question {
        padding: 1rem;
        border-radius: 0.8rem;
        background-color: #141926;

        p {
            color: #8b99bb;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.1rem;
            font-weight: 325;
            line-height: 1.4;
        }
    }

    .faq__question-title {
        display: flex;
        margin-bottom: 0.5rem;

        h2 {
            color: #e5ecf9;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.4rem;
            font-weight: 325;
            line-height: 1.3;
        }

        svg {
            fill: #353e54;
            flex-shrink: 0;
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
        }
    }

    .faq__socials {
        display: grid;
        grid-gap: 1rem;
        max-width: 48rem;
    }

    .faq__social {
        position: relative;
        padding: 2rem;
        border-radius: 1rem;
        background: linear-gradient(69deg, rgba(38, 51, 119, 0.6) 17.66%, rgba(71, 68, 200, 0.6) 101.56%);
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 0;
        }

        h2 {
            position: relative;
            color: #ffffff;
            font-family: 'Gotham Light', sans-serif;
            font-size: 2.3rem;
            font-weight: 300;
            z-index: 1;
        }

        p {
            position: relative;
            margin: 0.3rem 0 1.2rem;
            color: #b0cdfa;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            font-weight: 325;
            z-index: 1;
        }
    }

    @media screen and (min-width: 75em) {
        .faq {
            display: flex;
            align-items: stretch;
            height: 100%;

            > .card {
                &:first-of-type {
                    border-radius: 0.8rem 0 0 0.8rem;
                }

                &:last-of-type {
                    width: 40rem;
                    border-radius: 0 0.8rem 0.8rem 0;
                }
            }
        }

        .faq__socials {
            max-width: 100%;
        }

        ::v-deep {
            .app-layout__content,
            .app-layout__content-outer > .scroll-container,
            .app-layout__content-outer > .scroll-container > .scroll-container__content {
                flex: 1;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .faq > .card:last-child {
            width: 54rem;
        }

        .faq__question {
            padding: 2rem;

            p {
                font-size: 1.4rem;
            }
        }

        .faq__question-title {
            h2 {
                font-size: 1.8rem;
            }

            svg {
                width: 2.2rem;
                height: 2.2rem;
            }
        }

        .faq__socials {
            grid-gap: 2rem;
        }

        .faq__social {
            padding: 3rem;

            h2 {
                font-size: 3.8rem;
            }

            p {
                margin: 1rem 0 2.5rem;
                font-size: 1.5rem;
            }
        }
    }
</style>
